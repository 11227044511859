"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStreamingWorker = void 0;
const createStreamingWorker = () => {
    var blobURL = URL.createObjectURL(new Blob(['(', streamingWorker.toString(), ')()'], { type: 'application/javascript' }));
    var worker = new Worker(blobURL);
    URL.revokeObjectURL(blobURL);
    return worker;
};
exports.createStreamingWorker = createStreamingWorker;
const streamingWorker = () => {
    let index = 0;
    let frequency = 1;
    let measurementId;
    let productId;
    let productIdType;
    let readIos;
    let readValues;
    let token;
    let url;
    addEventListener('message', (e) => {
        try {
            var data = e.data;
            switch (data.type) {
                case 'start':
                    measurementId = data.measurementId;
                    productId = data.productId;
                    productIdType = data.productIdType;
                    readIos = data.readIos;
                    readValues = data.readValues;
                    token = data.token;
                    url = data.url;
                    frequency = data.frequency;
                    doCall();
                    break;
                case 'default':
                    console.error('Worker received unexpected message');
                    break;
            }
        }
        catch (error) {
            console.error('Error in init');
            postMessage({ type: 'error', message: error.message });
        }
    });
    function fakeExecuteCalls() {
        return sleep(1000).then(() => Promise.resolve(fakeIos));
    }
    const fakeIos = [
        {
            familyName: "COO",
            name: "COO-ParkingBrakeHigh-PIN",
            status: "OK",
            timestamp: "2024-04-09T13:26:40.596Z",
            unit: "-",
            value: "33",
            controlPointExist: false,
            valueType: "String",
            scaniaState: {
                typeOfInput: "inErr",
                typeOfOutput: "Internal",
                typeOfSignal: "Output",
                levelOfSignal: "Low",
                statusOfSignal: "ActiveFunction1"
            }
        }
    ];
    function doCall() {
        try {
            const start = Date.now();
            index = index + 1;
            const calls = setupCalls(productId, productIdType, readIos, readValues);
            executeCalls(calls)
                .then(result => {
                postMessage({ type: 'data', index, ios: result });
            })
                .catch(error => {
                console.error('Error from fetch: ', error.message);
                postMessage({ type: 'error', message: error.message });
            })
                .then(() => {
                waitIfNeeded(start, frequency).then(() => {
                    doCall();
                });
            });
        }
        catch (error) {
            postMessage({ type: 'error', message: error.message });
        }
    }
    const setupCalls = (productId, productIdType, readIos, readValues) => {
        const calls = [];
        if (readIos && readIos.length)
            readIos.map(o => {
                calls.push(fetchData(`${url}/api/sws-wcs/Product/ECU/ReadIoList?ioNameList=${o.ios.join('&ioNameList=')}&familyName=${o.ecu}&productIdentifier.Identifier=${productId}&productIdentifier.Type=${productIdType}`));
            });
        if (readValues && readValues.length)
            readValues.map(o => {
                calls.push(fetchData(`${url}/api/sws-wcs/Product/ECU/ReadValueList?ioNameList=${o.ios.join('&ioNameList=')}&familyName=${o.ecu}&productIdentifier.Identifier=${productId}&productIdentifier.Type=${productIdType}`));
            });
        return calls;
    };
    const executeCalls = (calls) => {
        return Promise.all(calls)
            .then(responses => {
            const filteredResponses = responses.filter(item => item !== undefined);
            return Promise.resolve(filteredResponses.flat());
        });
    };
    const fetchData = (url) => {
        return fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error, status: ${response.status}`);
            }
            return response.json();
        });
    };
    const waitIfNeeded = (start, frequency) => {
        const done = Date.now();
        const timeLeft = start + (1000 / frequency) - done;
        if (timeLeft > 0) {
            return sleep(timeLeft).then(() => Promise.resolve());
        }
        return Promise.resolve();
    };
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
            .then(() => Promise.resolve());
    }
};
