"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HostContext = void 0;
const react_1 = require("react");
exports.HostContext = (0, react_1.createContext)({
    rootChannelId: null,
    messageService: null,
    router: null,
    loadChunkControllerFactory: null,
    chunkHost: null,
    logger: null,
    tokenService: null,
    channels: null,
    http: null,
    guideDestroyers: null,
});
