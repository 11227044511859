"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupPreconditionsEvaluator = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const operations_1 = require("@guided-methods/operations");
const uuid_1 = require("uuid");
const setupPreconditionsEvaluator = (services, options) => {
    return async (command) => {
        if (command.type !== 'Once') {
            throw new Error('Not supported');
        }
        const conditionResults = [];
        const responseChannel = (0, messaging_common_1.createChannel)(operations_1.ConditionEvalResponse, (0, messaging_common_1.combine)(options.rootChannelId, (0, uuid_1.v4)()));
        const { requestId, conditions } = command.params;
        services.router.subscribe(responseChannel, (msg) => {
            if (msg.type !== messaging_common_1.GuideMessageType.Data) {
                return null;
            }
            switch (msg.data.type) {
                case operations_1.OperationUpstreamMessageType.OperationResult:
                    conditionResults.push({
                        operationIdentification: msg.data.operationIdentification,
                        result: msg.data.result,
                    });
                    break;
                case operations_1.OperationUpstreamMessageType.RequestResolvingStarted:
                    break;
                case operations_1.OperationUpstreamMessageType.OperationError:
                case operations_1.OperationUpstreamMessageType.OperationLoadingFailed:
                case operations_1.OperationUpstreamMessageType.ResponseResolvingFailure:
                case operations_1.OperationUpstreamMessageType.RequestResolvingFailure:
                    services.router.unsubscribe(responseChannel);
                    const yetToBeEvaluated = conditions.map((cond) => ({
                        operationIdentification: {
                            operationReference: cond.operationReference,
                            operationReferenceVersion: cond.operationReferenceVersion,
                            contractType: cond.contractType,
                        },
                        result: {
                            type: 'Error',
                            readoutError: null,
                            errorMessage: 'Not evaluated',
                        },
                    }));
                    const complemented = conditionResults.concat(yetToBeEvaluated.filter((i) => conditionResults.find((cr) => isSameOperation(cr.operationIdentification, i.operationIdentification)) === undefined));
                    command.done(requestId, complemented, new Error(msg.data.type));
                    return;
                default:
                    break;
            }
            if (conditionResults.length >= conditions.length) {
                services.router.unsubscribe(responseChannel);
                const errorOccurred = conditionResults.find((r) => r.result.type === 'Error') !== undefined;
                command.done(requestId, conditionResults, errorOccurred ? new Error('Could not evaluate all conditions') : undefined);
            }
        });
        function isSameOperation(l, r) {
            return (l.operationReference === r.operationReference &&
                l.operationReferenceVersion === r.operationReferenceVersion &&
                l.contractType.toUpperCase() === r.contractType.toUpperCase());
        }
        conditions.forEach((cond) => {
            services.messageService.send(operations_1.conditionEvalChannel, messaging_common_1.GuideMessageType.Data, command.params.context, {
                initialData: {
                    condition: cond.operationReference,
                },
                responseChannel,
            });
        });
    };
};
exports.setupPreconditionsEvaluator = setupPreconditionsEvaluator;
