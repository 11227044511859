"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RunnerStatusMonitor = void 0;
const React = __importStar(require("react"));
const messaging_common_1 = require("@guided-methods/messaging-common");
const host_context_1 = require("./host-context");
const models_1 = require("../models");
const react_bootstrap_1 = require("react-bootstrap");
const PreConditionsStatus_1 = require("./PreConditionsStatus");
const SpinnerWithCaption_1 = require("./SpinnerWithCaption");
const react_intl_1 = require("react-intl");
const RunnerStatusMonitor = ({ children, strict }) => {
    const [requiresConfirmation, setRequiresConfirmation] = React.useState(undefined);
    const [userHasConfirmed, setUserHasConfirmed] = React.useState(undefined);
    const [runnerStatus, setRunnerStatus] = React.useState(undefined);
    const [showPreconditionsView, setShowPreconditionsView] = React.useState(false);
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [latestExecutionStatusChange, setLatestExecutionStatusChange] = React.useState(undefined);
    const showSpinnerWithDelay = (enable = true, timeout = 1000) => {
        setShowSpinner(enable);
    };
    const intl = (0, react_intl_1.useIntl)();
    const { href } = window.location;
    const { router, channels, rootChannelId, messageService } = React.useContext(host_context_1.HostContext);
    const abort = () => {
        setShowPreconditionsView(false);
        showSpinnerWithDelay(false);
        if (latestExecutionStatusChange.responseChannel) {
            let errorMessage = thereWasAnErrorEvaluatingPreconditions(latestExecutionStatusChange) ||
                thereArePreConditionsThatDidNotPass(latestExecutionStatusChange)
                ? intl.formatMessage({ id: 'RunnerStatusMonitor.Caption.FailedToEvaluatePreconditions', defaultMessage: 'Failed to evaluate preconditions' })
                : intl.formatMessage({ id: 'RunnerStatusMonitor.Caption.Unknown', defaultMessage: 'Unknown' });
            messageService.send(latestExecutionStatusChange.responseChannel, messaging_common_1.GuideMessageType.Data, latestExecutionStatusChange.context, {
                type: 'Abort',
                requestId: latestExecutionStatusChange.requestId,
                conditions: latestExecutionStatusChange.preConditionsStatus?.conditions ?? [],
                errorMessage
            });
        }
    };
    const retry = () => {
        setShowPreconditionsView(false);
        showSpinnerWithDelay(false);
        if (latestExecutionStatusChange.responseChannel) {
            let errorMessage = thereWasAnErrorEvaluatingPreconditions(latestExecutionStatusChange) ||
                thereArePreConditionsThatDidNotPass(latestExecutionStatusChange)
                ? intl.formatMessage({ id: 'RunnerStatusMonitor.Caption.FailedToEvaluatePreconditions', defaultMessage: 'Failed to evaluate preconditions' })
                : intl.formatMessage({ id: 'RunnerStatusMonitor.Caption.Unknown', defaultMessage: 'Unknown' });
            messageService.send(latestExecutionStatusChange.responseChannel, messaging_common_1.GuideMessageType.Data, latestExecutionStatusChange.context, {
                type: 'Retry',
                requestId: latestExecutionStatusChange.requestId,
                conditions: latestExecutionStatusChange.preConditionsStatus?.conditions ?? [],
                errorMessage
            });
        }
    };
    React.useEffect(() => {
        const controller = router.subscribe(channels.browserRunnerStatusChannel, async (m, _, r) => {
            if (m.type !== messaging_common_1.GuideMessageType.Data) {
                return await r.next();
            }
            switch (m.data.type) {
                case models_1.BrowserRunnerStatusMessageType.SignalRConnected:
                    setRunnerStatus({
                        status: m.data.type,
                    });
                    break;
                case models_1.BrowserRunnerStatusMessageType.IframeHostReady:
                    setRunnerStatus({
                        status: m.data.type,
                    });
                    break;
                case models_1.BrowserRunnerStatusMessageType.GuideCompleted:
                    setLatestExecutionStatusChange(undefined);
                case models_1.BrowserRunnerStatusMessageType.Error:
                    {
                        setLatestExecutionStatusChange({
                            ...latestExecutionStatusChange,
                            requestId: undefined,
                            context: undefined,
                            preConditionsStatus: undefined,
                            status: m.data.type,
                        });
                    }
                    showSpinnerWithDelay(false);
                    setShowPreconditionsView(false);
                    break;
                case models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckStarted:
                    {
                        setLatestExecutionStatusChange({
                            status: m.data.type,
                            context: m.context,
                            requestId: m.data.requestId,
                            preConditionsStatus: {
                                conditions: m.data.conditions,
                                conditionResults: undefined,
                                resolvingFailure: undefined,
                            },
                        });
                        setShowPreconditionsView(true);
                        showSpinnerWithDelay(true);
                    }
                    break;
                case models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckPassed:
                    {
                        setLatestExecutionStatusChange({
                            status: m.data.type,
                            context: m.context,
                            requestId: m.data.requestId,
                            preConditionsStatus: {
                                conditions: m.data.conditions,
                                conditionResults: undefined,
                                resolvingFailure: false,
                            },
                        });
                        showSpinnerWithDelay(false);
                        setShowPreconditionsView(false);
                    }
                    break;
                case models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckFailed:
                    {
                        setLatestExecutionStatusChange({
                            responseChannel: m.data.responseChannel,
                            status: m.data.type,
                            requestId: m.data.requestId,
                            context: m.context,
                            preConditionsStatus: {
                                conditions: m.data.conditions,
                                conditionResults: m.data?.conditionResults,
                                resolvingFailure: m.data.resolvingFailure,
                            },
                        });
                    }
                    showSpinnerWithDelay(false);
                    setShowPreconditionsView(true);
                    break;
                default:
                    setLatestExecutionStatusChange(undefined);
                    setRunnerStatus({ status: undefined });
                    showSpinnerWithDelay(false);
            }
            return await r.next();
        });
        return () => {
            router.unsubscribe(channels.browserRunnerStatusChannel, controller);
        };
    }, [router, runnerStatus, latestExecutionStatusChange]);
    return (React.createElement("div", { className: "min-w-100" }, showPreconditionsView ? (React.createElement(react_bootstrap_1.Card, { bg: "Light", key: "Light", border: "Light", className: "border-0 min-vh-100" },
        React.createElement(react_bootstrap_1.Card.Header, null,
            React.createElement("b", null,
                React.createElement(react_intl_1.FormattedMessage, { "autotest-id": "title-preconditions", id: 'RunnerStatusMonitor.Caption.Preconditions', defaultMessage: 'Preconditions' }))),
        React.createElement(react_bootstrap_1.Card.Body, { className: "border-0 relative" },
            React.createElement(PreConditionsStatus_1.PreconditionsStatus, { router: router, messageService: messageService, conditions: latestExecutionStatusChange?.preConditionsStatus?.conditions ?? [], context: latestExecutionStatusChange?.context, conditionResults: latestExecutionStatusChange?.preConditionsStatus?.conditionResults, resolvingFailure: latestExecutionStatusChange?.preConditionsStatus?.resolvingFailure, handleConfirmation: (confirmed) => setUserHasConfirmed(confirmed), handleConfirmationRequired: (required) => setRequiresConfirmation(required), rootChannelId: rootChannelId }),
            showSpinner ? (React.createElement(SpinnerWithCaption_1.SpinnerWithCaption, { caption: intl.formatMessage({ id: 'RunnerStatusMonitor.Caption.Initializing', defaultMessage: 'Initializing...' }) })) : (React.createElement(React.Fragment, null))),
        React.createElement(react_bootstrap_1.Card.Footer, null,
            React.createElement(react_bootstrap_1.Button, { variant: "secondary", onClick: abort, className: "p-1 m-1", "autotest-id": "btn-abort" },
                React.createElement(react_intl_1.FormattedMessage, { id: 'RunnerStatusMonitor.Caption.Abort', defaultMessage: 'Abort' })),
            React.createElement(react_bootstrap_1.Button, { variant: "secondary", onClick: retry, className: "p-1 m-1", disabled: latestExecutionStatusChange?.preConditionsStatus?.resolvingFailure, "autotest-id": "btn-retry" },
                React.createElement(react_intl_1.FormattedMessage, { id: 'RunnerStatusMonitor.Caption.Retry', defaultMessage: 'Retry' }))))) : (children)));
};
exports.RunnerStatusMonitor = RunnerStatusMonitor;
const thereIsPendingPreconditionsEvaluation = (latestExecutionStatusChange) => latestExecutionStatusChange?.preConditionsStatus !== undefined &&
    latestExecutionStatusChange.status ===
        models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckStarted;
const thereWasAnErrorEvaluatingPreconditions = (latestExecutionStatusChange) => latestExecutionStatusChange?.status ===
    models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckFailed &&
    latestExecutionStatusChange?.preConditionsStatus?.resolvingFailure === true;
const thereArePreConditionsThatDidNotPass = (latestExecutionStatusChange) => latestExecutionStatusChange?.status ===
    models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckFailed &&
    latestExecutionStatusChange?.preConditionsStatus?.resolvingFailure === false;
const allPreconditionsEvaluatedAndPassed = (latestExecutionStatusChange) => latestExecutionStatusChange?.status ===
    models_1.BrowserRunnerStatusMessageType.GuidePreconditionsCheckPassed;
const thereIsAPreConditionsResult = (latestExecutionStatusChange) => latestExecutionStatusChange?.preConditionsStatus !== undefined;
