"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrowserRunnerStatusMessage = exports.ConditionFailureConfirmationResponseMessage = exports.ConditionResult = exports.BrowserRunnerStatusMessageType = void 0;
const operations_1 = require("@guided-methods/operations");
const io_ts_1 = require("io-ts");
const messaging_common_1 = require("@guided-methods/messaging-common");
var BrowserRunnerStatusMessageType;
(function (BrowserRunnerStatusMessageType) {
    BrowserRunnerStatusMessageType["IframeHostReady"] = "iframe-host-ready";
    BrowserRunnerStatusMessageType["SignalRConnected"] = "signalr-connected";
    BrowserRunnerStatusMessageType["GuidePreconditionsCheckStarted"] = "guide-preconditions-check-started";
    BrowserRunnerStatusMessageType["GuidePreconditionsCheckFailed"] = "guide-preconditions-check-failed";
    BrowserRunnerStatusMessageType["GuidePreconditionsCheckPassed"] = "guide-preconditions-check-passed";
    BrowserRunnerStatusMessageType["GuideContinuousConditionsCheckStarted"] = "guide-continuous-conditions-check-started";
    BrowserRunnerStatusMessageType["GuideContinuousConditionsCheckFailed"] = "guide-continuous-conditions-check-failed";
    BrowserRunnerStatusMessageType["GuideContinuousConditionsCheckStopped"] = "guide-continuous-conditions-check-stopped";
    BrowserRunnerStatusMessageType["GuideCompleted"] = "guide-completed";
    BrowserRunnerStatusMessageType["Error"] = "error";
})(BrowserRunnerStatusMessageType || (exports.BrowserRunnerStatusMessageType = BrowserRunnerStatusMessageType = {}));
exports.ConditionResult = (0, io_ts_1.type)({
    operationIdentification: operations_1.OperationIdentification,
    result: operations_1.ConditionEvalResultData,
}, 'ConditionEvalResult');
exports.ConditionFailureConfirmationResponseMessage = (0, io_ts_1.type)({
    requestId: io_ts_1.string,
    type: (0, io_ts_1.union)([(0, io_ts_1.literal)('Abort'), (0, io_ts_1.literal)('Retry'), (0, io_ts_1.literal)('Pending'), (0, io_ts_1.literal)('Resolved')]),
    conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
    errorMessage: io_ts_1.string,
}, 'ConditionFailureConfirmationResponseMessage');
exports.BrowserRunnerStatusMessage = (0, io_ts_1.union)([
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.IframeHostReady),
    }, 'IFrameHostReady'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.SignalRConnected),
    }, 'SignalRConnected'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.Error),
        message: io_ts_1.string,
    }, 'Error'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuidePreconditionsCheckStarted),
        conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
        requestId: io_ts_1.string,
    }, 'GuidePreconditionsCheckStarted'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuidePreconditionsCheckPassed),
        requestId: io_ts_1.string,
        conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
    }, 'GuidePreconditionsCheckPassed'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuidePreconditionsCheckFailed),
        requestId: io_ts_1.string,
        conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
        conditionResults: (0, io_ts_1.array)(exports.ConditionResult),
        resolvingFailure: io_ts_1.boolean,
        responseChannel: (0, messaging_common_1.GuideMessageChannel)(exports.ConditionFailureConfirmationResponseMessage),
    }, 'GuidePreconditionsCheckFailed'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuideContinuousConditionsCheckStarted),
        requestId: io_ts_1.string,
        conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
    }, 'GuideContinuousConditionsCheckFailed'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuideContinuousConditionsCheckFailed),
        requestId: io_ts_1.string,
        conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
        conditionResults: (0, io_ts_1.array)(exports.ConditionResult),
        resolvingFailure: io_ts_1.boolean,
        checksStillRunning: io_ts_1.boolean,
    }, 'GuideContinuousConditionsCheckFailed'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuideContinuousConditionsCheckStopped),
        requestId: io_ts_1.string,
        conditions: (0, io_ts_1.array)(operations_1.OperationIdentification),
    }, 'GuideContinuousConditionsCheckFailed'),
    (0, io_ts_1.type)({
        type: (0, io_ts_1.literal)(BrowserRunnerStatusMessageType.GuideCompleted),
    }, 'GuideCompleted'),
], 'BrowserRunnerStatusMessage');
