"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadChunkControllerWebWorker = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
const services_1 = require("../services");
const loadChunkControllerWebWorker = (loadChunkControllerFactory, guideStorage) => async (request, transport, r) => {
    if (request.type !== messaging_common_1.GuideMessageType.Data || request.data.type !== messaging_common_1.ChunkType.Flow)
        return await r.next();
    const { statusChannel } = request.data;
    const containerProvider = async (loadData) => {
        const startTime = new Date();
        transport.logger.debug({
            message: 'Retrieving chunks from storage',
            mode: messaging_common_1.LogMode.Framework,
            layer: messaging_common_1.LogLayerNames.Performance,
            ...request.context
        });
        let chunkSourceCode;
        try {
            chunkSourceCode = loadData.url.startsWith('http') ? `importScripts('${loadData.url}');` : await guideStorage(loadData.url);
        }
        catch (e) {
            transport.logger.error({ message: `Error when retrieving chunk code (${loadData.url}) from storage`, mode: messaging_common_1.LogMode.Framework, layer: messaging_common_1.LogLayerNames.Transport, ...request.context }, e.error);
            transport.messageService.send(statusChannel, messaging_common_1.GuideMessageType.Data, request.context, {
                type: messaging_common_1.ChunkStatusMessageType.ChunkLoadingFailed,
            });
            return;
        }
        const endtime = +(new Date()) - (+startTime);
        transport.logger.debug({
            message: `Retrieved chunks from storage in ${endtime} ms`,
            mode: messaging_common_1.LogMode.Framework,
            layer: messaging_common_1.LogLayerNames.Performance,
            executionDuration: endtime,
            ...request.context
        });
        transport.logger.debug({ message: 'Creating worker', ...request.context });
        const worker = createWorker(chunkSourceCode, transport);
        transport.logger.debug({ message: 'Created worker', ...request.context });
        worker.addEventListener('error', e => {
            transport.logger.error('Error in webworker', e.error);
            transport.messageService.send(statusChannel, messaging_common_1.GuideMessageType.Data, request.context, {
                type: messaging_common_1.ChunkStatusMessageType.ChunkError,
                errorMessage: e.message,
            });
        });
        return {
            messageTransport: new services_1.WebWorkerMessageService(worker, (0, messaging_common_1.enrichLogger)(transport.logger, { layer: 'transport' })),
            release: () => worker.terminate(),
        };
    };
    const controller = loadChunkControllerFactory.controller(containerProvider);
    return await controller(request, transport, r);
};
exports.loadChunkControllerWebWorker = loadChunkControllerWebWorker;
const loadBlob = (code) => new Blob([code], { type: 'application/javascript' });
const createWorker = (url, transport) => {
    transport?.logger.debug('Loading chunk');
    const blob = loadBlob(url);
    transport?.logger.debug('Loaded chunk');
    const blobUrl = window.URL.createObjectURL(blob);
    const worker = new Worker(blobUrl);
    return worker;
};
