"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreconditionsStatus = void 0;
const react_1 = __importStar(require("react"));
const hooks_1 = require("../hooks");
const TestStatus_1 = require("./TestStatus");
const tds_1 = require("@guided-methods/tds");
const react_intl_1 = require("react-intl");
const PreconditionsStatus = ({ router, messageService, context, conditions, handleConfirmation, handleConfirmationRequired, rootChannelId, conditionResults, resolvingFailure, }) => {
    const operationAssetsResult = (0, hooks_1.useOperationAssets)(router, messageService, context, conditions, rootChannelId);
    (0, react_1.useEffect)(() => {
        if (operationAssetsResult?.type === 'Error') {
            handleConfirmationRequired(true);
        }
    }, [operationAssetsResult]);
    const intl = (0, react_intl_1.useIntl)();
    return (react_1.default.createElement("div", null,
        aConditionCouldNotBeEvaluated(resolvingFailure, conditionResults) && (react_1.default.createElement("div", { className: "pb-2", "autotest-id": 'label-aConditionCouldNotBeEvaluated' },
            react_1.default.createElement(tds_1.Message, { type: "error", headline: intl.formatMessage({
                    id: 'PreconditionStatus.Title.ConditionsNotEvaluated',
                    defaultMessage: 'Failed to evaluate preconditions status',
                }), message: intl.formatMessage({
                    id: 'PreconditionStatus.PlainText.ConditionsNotEvaluated',
                    defaultMessage: 'The system encountered an issue evaluating precondition statuses. Please close the guide and try again',
                }) }))),
        allConditionsEvaluatedButNotAllPassed(resolvingFailure, conditionResults) && (react_1.default.createElement("div", { className: "pb-2", "autotest-id": 'label-allConditionsEvaluatedButNotAllPassed' },
            react_1.default.createElement(tds_1.Message, { type: "warning", headline: intl.formatMessage({
                    id: 'PreconditionStatus.Title.ConditionsNotFulfilled',
                    defaultMessage: 'One or more preconditions are not met',
                }) }))),
        operationAssetsResult?.type === 'Pending' ? (react_1.default.createElement(react_1.default.Fragment, null)) : operationAssetsResult?.type === 'Error' ? (react_1.default.createElement("span", null, intl.formatMessage({
            id: 'PreconditionStatus.PlainText.MissingInformation',
            defaultMessage: 'Failed to retrieve information about preconditions.',
        }))) : (react_1.default.createElement(react_1.default.Fragment, null)),
        !aConditionCouldNotBeEvaluated(resolvingFailure, conditionResults) &&
            conditionResults &&
            conditionResults.map((r) => (react_1.default.createElement("div", { className: "pl-5", key: r.operationIdentification.operationReference, "autotest-id": 'text-operationReference' },
                react_1.default.createElement(TestStatus_1.TestStatus, { passed: r.result.type === 'Error' ? false : r.result.passed, caption: operationAssetsResult?.type === 'Ok'
                        ? operationAssetsResult?.assets.find((a) => isSameOperation(a, r.operationIdentification))?.what?.text
                        : undefined }),
                react_1.default.createElement("span", { "autotest-id": `result-type-${r.result.type}` }))))));
    function allConditionsEvaluatedButNotAllPassed(resolvingFailure, conditionResults) {
        return (!resolvingFailure &&
            conditionResults &&
            conditionResults.find((r) => r.result.type === 'Error') === undefined &&
            conditionResults.find((r) => r.result.type === 'Ok' && !r.result?.passed) !== undefined);
    }
    function aConditionCouldNotBeEvaluated(resolvingFailure, conditionResults) {
        return (resolvingFailure ||
            (conditionResults && conditionResults.find((r) => r.result.type === 'Error') !== undefined));
    }
    function isSameOperation(l, r) {
        return (l.operationReference === r.operationReference &&
            l.operationReferenceVersion === r.operationReferenceVersion &&
            l.contractType.toUpperCase() === r.contractType.toUpperCase());
    }
};
exports.PreconditionsStatus = PreconditionsStatus;
