"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareLocalizedAsset = void 0;
const React = __importStar(require("react"));
const client_1 = require("react-dom/client");
const uuid_1 = require("uuid");
const react_router_dom_1 = require("react-router-dom");
const messaging_common_1 = require("@guided-methods/messaging-common");
const services_1 = require("./services");
const setup_1 = require("./setup");
const components_1 = require("./components");
const env_resolver_1 = require("./services/env-resolver");
require("./index.css");
require("./tailwind.css");
require("bootstrap/dist/css/bootstrap.min.css");
const react_intl_1 = require("react-intl");
let startTime = new Date();
const tokenService = new services_1.TokenService();
let defaultOptions = {
    runnerId: (0, uuid_1.v4)(),
    messageUrl: (0, env_resolver_1.getGuideMessageGatewayByEnv)(),
    signalrHubUrl: (0, env_resolver_1.getSignalRHubUrlByEnv)(),
    guideRequestServiceUrl: (0, env_resolver_1.getGuideRequestServiceByEnv)(),
    registryUrl: (0, env_resolver_1.getRegistryByEnv)(),
    bucketUrl: (0, env_resolver_1.getGuideViewsBucketUrlByEnv)(),
    storageUrl: (0, env_resolver_1.getGuideStorageByEnv)(),
    loggingServiceUrl: (0, env_resolver_1.getLoggingServiceByEnv)(),
    operationProvisionerUrl: (0, env_resolver_1.getOperationProvisionerUrlByEnv)(),
    guideDiscoveryUrl: (0, env_resolver_1.getGuideDiscoveryUrlByEnv)(),
    operationResolverUrl: (0, env_resolver_1.getOperationResolverUrlByEnv)(),
    streamingUrl: (0, env_resolver_1.getStreamingUrlByEnv)(),
    wcsUrl: (0, env_resolver_1.getWcsUrlByEnv)(),
    appName: 'sws-guide-host-app',
    appVersion: APP_VERSION,
    tokenService,
    testMode: false,
    requestId: undefined,
    router: (0, messaging_common_1.router)()
};
const flat_1 = require("flat");
const get_library_assets_service_1 = require("./services/get-library-assets-service");
const messageLog = process.env.TEST_MODE === 'dev' ? new services_1.MessageLog() : undefined;
let customOptions;
if (process.env.TEST_MODE === 'dev') {
    customOptions = {
        ...defaultOptions,
        messageTracker: messageLog.push.bind(messageLog),
    };
}
const prepareLocalizedAsset = (fullLocaleId, assets) => {
    if (!fullLocaleId || fullLocaleId === '') {
        throw new Error('fullLocaleId not provided');
    }
    let language = fullLocaleId.split(/[-_]/)[0];
    return { language, messages: { [language]: (0, flat_1.flatten)(assets) } };
};
exports.prepareLocalizedAsset = prepareLocalizedAsset;
const options = (0, setup_1.applyDefaults)(customOptions);
let matches = window.location.href.match(/^.*?.*lang=([^&]*).*$/);
let fullLocaleId = matches && matches.length > 1 ? matches[1] : navigator.language;
const sources = [
    { repoName: 'sws-guide-host-app', assetPath: 'assets/gui-strings.json' },
    { repoName: 'sws-guide-ts-packages', assetPath: 'tds/assets/gui-strings.json' },
];
const ignoreDefaultMessageError = (error) => {
    if (typeof error.message === 'string' &&
        error.message.includes('[@formatjs/intl Error MISSING_TRANSLATION]')) {
        console.error(error.message);
    }
};
const props = (0, exports.prepareLocalizedAsset)(fullLocaleId, {});
(0, get_library_assets_service_1.getLibraryAssets)(sources, props.language, fullLocaleId)
    .catch((e) => {
    console.error(`Failed to load localized assets for '${fullLocaleId}'`);
})
    .then((config) => {
    const rootEl = document.getElementById('root');
    const root = (0, client_1.createRoot)(rootEl);
    root.render(React.createElement(react_router_dom_1.BrowserRouter, null,
        React.createElement(react_router_dom_1.Routes, null,
            React.createElement(react_router_dom_1.Route, { path: "/callback", element: React.createElement(components_1.Callback, { tokenService: tokenService, silent: false }) }),
            React.createElement(react_router_dom_1.Route, { path: "/silent-renew-callback", element: React.createElement(components_1.Callback, { tokenService: tokenService, silent: true }) }),
            React.createElement(react_router_dom_1.Route, { element: React.createElement(react_router_dom_1.Navigate, { to: "/", replace: true }) }),
            React.createElement(react_router_dom_1.Route, { path: "/:requestId?", element: React.createElement(react_intl_1.IntlProvider, { locale: config.language, messages: config.messages[config.language], defaultLocale: config.language, key: config.language, onError: ignoreDefaultMessageError },
                    React.createElement(components_1.Authenticated, { tokenService: tokenService },
                        React.createElement(components_1.App, { launcher: (requestId) => {
                                const services = (0, setup_1.setupServices)({ ...options, testMode: !Boolean(requestId) });
                                try {
                                    (0, setup_1.setupRouting)(services);
                                    const endTime = +new Date() - +startTime;
                                    services.logger.debug({
                                        message: `Host loaded in ${endTime} ms`,
                                        mode: messaging_common_1.LogMode.Framework,
                                        requestId,
                                        layer: messaging_common_1.LogLayerNames.Performance,
                                        executionDuration: endTime,
                                    });
                                }
                                catch (err) {
                                    services.logger.error({
                                        message: 'Failed to setup routing',
                                        requestId,
                                        mode: messaging_common_1.LogMode.Framework,
                                        layer: messaging_common_1.LogLayerNames.Performance,
                                    });
                                }
                                try {
                                    if (requestId) {
                                        (0, setup_1.startGuideFromRequest)(requestId, options, services);
                                    }
                                }
                                catch (err) {
                                    services.logger.error({
                                        message: 'Failed to start Guide',
                                        requestId,
                                        mode: messaging_common_1.LogMode.Framework,
                                        layer: messaging_common_1.LogLayerNames.Performance,
                                    });
                                }
                                return services;
                            }, options: options, messageLog: messageLog }))) }))));
});
