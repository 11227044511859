"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebWorkerMessageService = void 0;
const messaging_common_1 = require("@guided-methods/messaging-common");
class WebWorkerMessageService {
    constructor(worker, logger) {
        this.worker = worker;
        this.logger = logger;
        this.handlers = [];
        this.wrapHandler = (handler) => (ev) => {
            let result;
            try {
                if (ev.data?.to !== '$logger')
                    this.logger.debug({ message: `Received message from a worker: ${JSON.stringify(ev.data)}`, ...ev.data.context });
                result = (0, messaging_common_1.validate)(messaging_common_1.GuideMessage, ev.data);
            }
            catch (e) {
                this.logger.debug({ message: `Unknown message format from a worker, skipping, ${e.message}`, ...ev.data.context });
            }
            result && handler(result);
        };
    }
    async send(message) {
        try {
            this.logger.debug({ message: `Sending message to a worker: ${JSON.stringify(message)}`, ...message.context });
            const transformed = (0, messaging_common_1.substituteChannelsWithId)(message);
            this.worker.postMessage(transformed);
        }
        catch (e) {
            this.logger.error({ message: `Failed to send message to a worker with channel ${message.to}`, ...message.context });
            throw e;
        }
    }
    onGuideMessage(handler) {
        const wrapped = this.wrapHandler(handler);
        this.handlers.push(wrapped);
        this.worker.addEventListener('message', wrapped);
        return () => {
            const handlers = this.handlers.filter((h) => h === wrapped);
            this.handlers = this.handlers.filter((h) => h !== wrapped);
            handlers.forEach((h) => this.worker.removeEventListener('message', h));
        };
    }
}
exports.WebWorkerMessageService = WebWorkerMessageService;
