"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupServices = exports.startGuideFromRequest = exports.applyDefaults = void 0;
const guide_registry_1 = require("../services/guide-registry");
const uuid_1 = require("uuid");
const messaging_common_1 = require("@guided-methods/messaging-common");
const chunk_host_1 = require("@guided-methods/chunk-host");
const services_1 = require("../services");
const setup_runner_status_channels_1 = require("./setup-runner-status-channels");
const models_1 = require("../models");
const controllers_1 = require("../controllers");
const logging_service_1 = require("../services/logging/logging-service");
const log_filter_1 = require("./log-filter");
const preconditions_evaluator_service_1 = require("../services/preconditions-evaluator-service");
const url_helpers_1 = require("../utils/url-helpers");
const env_resolver_1 = require("../services/env-resolver");
const defaultOptions = {
    chunkControlKeepAliveTimeoutMs: 60000,
    chunkStatusKeepAliveTimeoutMs: 60000,
    chunkLoadTimeoutMs: 10000,
    chunkStopTimeoutMs: 5000,
    guideControlKeepAliveTimeoutMs: 60000,
    discoverTimeoutMs: 10000,
    guideSendUpstreamKeepAliveIntervalMs: 5000,
};
const applyDefaults = (opts) => ({
    ...defaultOptions,
    ...opts,
});
exports.applyDefaults = applyDefaults;
const startGuideFromRequest = (requestId, hostOptions, hostServices) => {
    let { router, logger, messageService, channels, http, rootChannelId, preconditionsEvaluator, launchDarklyService } = hostServices;
    let { guideRequestServiceUrl } = hostOptions;
    (0, services_1.readGuideRequest)({
        router,
        logger,
        messageService,
        channels,
        http,
        preconditionsEvaluator,
        launchDarklyService
    }, { rootChannelId, guideRequestServiceUrl }, requestId);
};
exports.startGuideFromRequest = startGuideFromRequest;
const handleSubGuideStartRequests = (hostOptions, hostServices) => {
    let { router, logger, messageService, channels, rootChannelId } = hostServices;
    const startSuffix = 'start-sub-guide';
    router.on((m) => m.to.endsWith(startSuffix), async (m, t) => {
        switch (m.type) {
            case messaging_common_1.GuideMessageType.KeepAlive:
                break;
            case messaging_common_1.GuideMessageType.Data:
                logger.info({
                    message: `Starting sub-guide with request ${m.data.requestId}`,
                    data: m.data,
                });
                (0, exports.startGuideFromRequest)(m.data.requestId, hostOptions, hostServices);
            case messaging_common_1.GuideMessageType.EndChannel:
                await messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, {}, {
                    type: models_1.BrowserRunnerStatusMessageType.GuideCompleted,
                });
        }
    });
};
const setupServices = (orgOptions) => {
    let options = orgOptions;
    const { runnerId, signalrHubUrl, tokenService, messageUrl, router, registryUrl, streamingUrl, wcsUrl, storageUrl, testMode } = options;
    if (testMode) {
        options = {
            ...options,
            guideControlKeepAliveTimeoutMs: 10000,
        };
    }
    const rootChannelId = (0, chunk_host_1.makeRunnerRoot)(runnerId, true);
    const { showLocalLogs, showVerboseLogging, showXstateLayerLogging } = (0, url_helpers_1.getQueryParameters)();
    const localLoggingSelected = showLocalLogs === true;
    let selectedLogLevel = log_filter_1.LogFilterLevel.Verbose;
    const logConditional = (transport) => (0, messaging_common_1.logWhen)(transport, (0, log_filter_1.getLogFilter)(selectedLogLevel));
    const consoleLogger = logConditional((0, services_1.createBrowserConsoleLogger)({ showLocalLogs, showVerboseLogging, showXstateLayerLogging }));
    const loggingService = logConditional((0, logging_service_1.createLogger)(tokenService, options.appName, options.appVersion, runnerId, options.loggingServiceUrl, (0, env_resolver_1.getEnvironment)()));
    const logger = !localLoggingSelected
        ? new messaging_common_1.Logger(loggingService)
        : new messaging_common_1.Logger((0, messaging_common_1.combineLogOutput)(consoleLogger, loggingService));
    const http = new services_1.AxiosHttpService({}, logger, tokenService);
    const launchDarklyService = new services_1.LaunchDarklyService(tokenService, logger, http);
    const chunkHostServices = (0, chunk_host_1.setupChunkHost)({ ...options, logger, uuid: uuid_1.v4, rootChannelId });
    const { messageService } = chunkHostServices;
    const guideRegistry = (0, guide_registry_1.createGuideRegistry)(http, registryUrl);
    const streamingService = new services_1.StreamingService(tokenService, wcsUrl, messageService, logger);
    const browserHostChannels = (0, setup_runner_status_channels_1.setupRunnerStatusChannels)(rootChannelId);
    const preconditionsEvaluator = (0, preconditions_evaluator_service_1.setupPreconditionsEvaluator)({ messageService, channels: browserHostChannels, logger, router }, { retryTimeout: -1, rootChannelId });
    const guideHostServices = (0, chunk_host_1.setupGuideHost)({
        ...options,
        logger,
        guideRegistry,
        uuid: uuid_1.v4,
        messageService,
        rootChannelId,
    });
    const channels = {
        ...chunkHostServices.channels,
        ...guideHostServices.channels,
        ...browserHostChannels,
    };
    let signalRConnected = false;
    const signalRService = new services_1.SignalrService(logger, tokenService, signalrHubUrl, (reconnecting) => {
        logger.info({
            message: 'Connected to SignalR hub',
            mode: messaging_common_1.LogMode.Framework,
            layer: 'SignalR',
        });
        if (reconnecting) {
            signalRService.unsubscribe(rootChannelId);
        }
        signalRService.subscribe(rootChannelId);
        signalRService.onGuideMessage((m) => messageService.forward(m));
        if (!signalRConnected) {
            messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, {}, {
                type: models_1.BrowserRunnerStatusMessageType.SignalRConnected,
            });
            signalRConnected = true;
        }
        else {
            logger.info({
                message: 'Already connected to SignalR',
                mode: messaging_common_1.LogMode.Framework,
                layer: 'SignalR',
            });
        }
    }, (error) => {
        messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, {}, {
            type: models_1.BrowserRunnerStatusMessageType.Error,
            message: `Failed to connect to SignalR - Upstream communication lost. ${error ?? ''}`,
        });
    });
    signalRService.connect().catch((e) => {
        console.error('signalR error');
        messageService.send(channels.browserRunnerStatusChannel, messaging_common_1.GuideMessageType.Data, {}, {
            type: models_1.BrowserRunnerStatusMessageType.Error,
            message: 'Failed to connect to SignalR - Upstream communication not established',
        });
    });
    const defController = (0, controllers_1.defaultController)(messageUrl, http, logger, rootChannelId, streamingService);
    const guideStorage = (0, services_1.createGuideStorage)(http, storageUrl);
    let hostServices = {
        ...chunkHostServices,
        ...guideHostServices,
        rootChannelId,
        router,
        logger,
        channels,
        http,
        tokenService,
        defaultController: defController,
        guideStorage,
        preconditionsEvaluator,
        launchDarklyService,
        guideDestroyers: [],
    };
    handleSubGuideStartRequests(options, hostServices);
    return hostServices;
};
exports.setupServices = setupServices;
