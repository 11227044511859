"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGuideStorage = void 0;
const createGuideStorage = (http, storageUrl) => async (url) => {
    try {
        const response = await http.get(`${storageUrl}chunkUrl=${encodeURIComponent(url)}`);
        const { status, data } = response;
        if (status === 200) {
            return data;
        }
        else {
            throw new Error(`registry responded with ${status}`);
        }
    }
    catch (e) {
        throw new Error(`Failed to fetch guide descriptor for ${url}, error was: ${e.message}`);
    }
};
exports.createGuideStorage = createGuideStorage;
