"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignalrService = void 0;
const signalr_1 = require("@microsoft/signalr");
const messaging_common_1 = require("@guided-methods/messaging-common");
const defaultRetryDelay = 3000;
class SignalrService {
    get connected() {
        return this._connected;
    }
    constructor(logger, tokenService, notificationServiceUrl, init, handleDisconnect) {
        this.logger = logger;
        this.tokenService = tokenService;
        this.notificationServiceUrl = notificationServiceUrl;
        this.init = init;
        this.handleDisconnect = handleDisconnect;
        this.handlers = [];
        this._connected = false;
    }
    async connect(retryDelays = [defaultRetryDelay, defaultRetryDelay * 2, defaultRetryDelay * 3]) {
        this.hubConnection = new signalr_1.HubConnectionBuilder()
            .withUrl(`${this.notificationServiceUrl}hubs/notificationservice`, {
            accessTokenFactory: () => this.tokenService.getToken(),
        })
            .configureLogging(signalr_1.LogLevel.None)
            .withAutomaticReconnect(retryDelays)
            .build();
        try {
            this.hubConnection.onreconnecting((error) => {
                this._connected = false;
                this.logger.warn({
                    message: 'SignalR connection lost, reconnecting...',
                    mode: messaging_common_1.LogMode.Framework,
                    layer: 'SignalR',
                });
            });
            this.hubConnection.onreconnected((id) => {
                this._connected = true;
                this.logger.info({
                    message: 'SignalR connection reconnected',
                    mode: messaging_common_1.LogMode.Framework,
                    layer: 'SignalR',
                });
                this.init(true);
            });
            this.hubConnection.onclose((error) => {
                this.logger.error({
                    message: 'SignalR connection lost',
                    mode: messaging_common_1.LogMode.Framework,
                    layer: 'SignalR',
                });
                this.handleDisconnect(error);
            });
            this.hubConnection.keepAliveIntervalInMilliseconds = 10000;
            this.hubConnection.serverTimeoutInMilliseconds = 20000;
            await this.hubConnection.start().then(() => (this._connected = true));
        }
        catch (e) {
            this.logger.error({
                message: 'Failed to connect to SignalR. Aborting initialization',
                mode: messaging_common_1.LogMode.Framework,
                layer: 'SignalR',
            });
            throw e;
        }
        return this.init(false);
    }
    stop() {
        this.logger.debug({
            message: 'SignalR connection close requested',
            mode: messaging_common_1.LogMode.Framework,
            layer: 'SignalR',
        });
        return this.hubConnection ? this.hubConnection.stop() : Promise.resolve();
    }
    subscribe(groupId) {
        return this.hubConnection.invoke('subscribe', groupId, '', '');
    }
    unsubscribe(groupId) {
        return this.hubConnection.invoke('unsubscribe', groupId, '', '');
    }
    onGuideMessage(handler) {
        if (!this.hubConnection) {
            return;
        }
        const wrapped = this.wrapHandler(handler);
        this.handlers.push(wrapped);
        this.hubConnection.on('message', wrapped);
        return wrapped;
    }
    offGuideMessage(handler) {
        if (!this.hubConnection) {
            return;
        }
        const handlers = this.handlers.filter((h) => h.handler === handler || h === handler);
        this.handlers = this.handlers.filter((h) => h.handler !== handler && h !== handler);
        handlers.forEach((h) => this.hubConnection.off('message', h));
    }
    wrapHandler(handler) {
        const wrapper = (ev) => {
            let result;
            try {
                this.logger.info({
                    message: `Received message from SignalR: ${JSON.stringify(ev)}`,
                    mode: messaging_common_1.LogMode.Framework,
                    layer: 'SignalR',
                    request: ev
                });
                result = (0, messaging_common_1.validate)(messaging_common_1.GuideMessage, ev.context);
            }
            catch (e) {
                this.logger.warn({
                    message: `Unknown message format from SignalR, skipping, ${e.message}`,
                    mode: messaging_common_1.LogMode.Framework,
                    layer: 'SignalR',
                });
            }
            result && handler(result);
        };
        wrapper.handler = handler;
        return wrapper;
    }
}
exports.SignalrService = SignalrService;
